import { GetterTree } from 'vuex';
import { IInventoryState, IWallet, IFiatBalances, IClaimFee } from './types';
import { IRootState } from '../types';
import { UserItem, IGyriUserItem } from '~/types/user-items';

export const getters: GetterTree<IInventoryState, IRootState> = {
  gyriTollBalance(state: IInventoryState): number {
    const tollWallet = state.wallets.find((wallet: IWallet) => {
      return wallet.symbol === 'GALA[GYRI]';
    });

    return Number(tollWallet?.balance?.confirmed ?? 0);
  },

  galaBalance(state: IInventoryState): number {
    const galaWallet = state.wallets.find((wallet: IWallet) => {
      return wallet.symbol === 'GALA[ETH]';
    });

    if (
      galaWallet &&
      galaWallet.balance &&
      galaWallet.balance.confirmed &&
      galaWallet.balance.unconfirmed
    ) {
      const { unconfirmed, confirmed } = galaWallet.balance;
      const lesserBalance = Math.min(+confirmed, +unconfirmed);

      return lesserBalance;
    }

    return 0;
  },

  getFiatBalances(state: IInventoryState): IFiatBalances {
    const { wallets } = state;
    const fiatBalances: IFiatBalances = { total: 0 };

    for (const wallet of wallets) {
      const { symbol, balance, receiveAddress, coinPrice } = wallet;
      let usdVal = 0;

      if (receiveAddress) {
        const lesserBalance = Math.min(
          +balance.confirmed,
          +balance.unconfirmed,
        );

        usdVal = coinPrice * lesserBalance;
      }

      fiatBalances[symbol] = usdVal;
      fiatBalances.total += usdVal;
    }

    return fiatBalances;
  },

  tokenClaimFeeId(state: IInventoryState) {
    const claimFee = state.claimFees.find(
      (fee) => fee.network === state.claimNetwork.network,
    ) as IClaimFee;

    return claimFee.id;
  },

  getItemsByGryiId: (state: IInventoryState) => (gyriId: string) => {
    const { userItems }: { userItems: UserItem[] } = state;

    const matchingGyriItems = userItems.filter((item) => {
      const { gyriTokenClassKey } = item as IGyriUserItem;
      const { collection, category, type, additionalKey } =
        gyriTokenClassKey || {};
      return `${collection}|${category}|${type}|${additionalKey}` === gyriId;
    });

    return matchingGyriItems;
  },

  getWallets: (state: IInventoryState) => () => {
    return state.wallets || [];
  },

  getVestingSchedule: (state: IInventoryState) => (gyriId: string) => {
    const schedule = state.vestingScheduleByGyriId?.[gyriId] || [];
    return schedule;
  },

  getUsersMintTokenDetails: (state: IInventoryState) => (gyriId: string) => {
    const userMintDetails = state.userMintTokenDetails?.[gyriId] || {};
    return userMintDetails;
  },

  getTokenRedistributionPoolAmount:
    (state: IInventoryState) => (gyriId: string) => {
      const tokenRedistributionPoolAmount =
        state.tokenRedistributionPoolAmount?.[gyriId];
      return tokenRedistributionPoolAmount;
    },
};
