import numeral from 'numeral';

export const formatNumber = (value: number, formatString: string) => {
  const valWithMin = +value >= 1e-6 ? +value : 0;
  const result = numeral(valWithMin.toFixed(8)).format(formatString);
  return result;
};

export function formatChainSymbol(chain: string) {
  const filtered = chain?.replace?.(/gyri/i, 'Gala Chain');
  return filtered?.replace?.(/gyri/i, filtered) ?? '';
}

export function cutNumberDecimals(value?: number, decimalPlaces = 2) {
  return +Number(value ?? 0)?.toFixed?.(decimalPlaces) || 0;
}

export function formatNumberWithCommas(
  num: number | null | undefined,
  decimals = 2,
) {
  return (num || 0).toLocaleString('en-US', {
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  });
}

export function getPercFormat(value: number, decimals = 2, includeSign = true) {
  const sign = includeSign ? (value >= 0 ? '+' : '-') : '';
  return sign + formatNumberWithCommas(value || 0, decimals) + '%';
}

export function secsToDays(secs: number) {
  const days = (secs || 0) / (60 * 60 * 24);
  return days;
}

export function getDecimalForm(num: number, decimal: number = 2) {
  const value = isNaN(num) ? 0 : num || 0;
  return parseFloat(value?.toFixed?.(decimal));
}

// Checks if string is valid number even if it has commas, percent sign, +, or decimal
export function isValidNumber(value: string) {
  // Regex to validate format
  const regex = /^[+-]?\d{1,3}(,\d{3})*(\.\d+)?%?$/;

  if (!regex.test(value)) {
    return false; // Fail if format doesn't match
  }

  // Remove commas and the percent sign for further checking
  const cleanedValue = value.replace(/,/g, '').replace(/%$/, '');

  // Check if the remaining value is a valid number
  return !isNaN(parseFloat(cleanedValue)) && isFinite(+cleanedValue);
}

export function truncateAddress(address: string, charCount: number = 6) {
  const string = `${address.slice(0, charCount)}....${address.substr(
    address.length - 4,
    address.length,
  )}`;

  return string;
}
