import { MutationTree } from 'vuex';
import _sumBy from 'lodash.sumby';
import { UserItem, EthUserItem, GyriUserItem } from '~/types/user-items';
import { ChainNetwork, EthereumTokenStandard } from '~/types/chain';
import { getUniqueInventoryPath } from '~/utils/userItem';
import {
  IInventoryState,
  IWallet,
  IClaimFee,
  IUpdateUserItemData,
  IExchangeReward,
  IFilmTokenVestingItem,
  IUsersMintTokenDetails,
} from './types';
import { deepSet } from '~/store/store-utils';

const bridgingBannedTokenNames = [
  'Freezo',
  'T-Rex',
  'Avalanche',
  'Jet',
  'Triple Threat',
  'Coinbase',
];

export const mutations: MutationTree<IInventoryState> = {
  setWalletState(inventoryState: IInventoryState, payload: IWallet[]) {
    if (payload && Array.isArray(payload)) {
      inventoryState.wallets = payload.map((wallet, index) => {
        const currentWalletInState = inventoryState.wallets.find(
          (stateWallet) => stateWallet.symbol === wallet.symbol,
        );
        wallet.coinPrice =
          (currentWalletInState && currentWalletInState.coinPrice) || 0;

        return wallet;
      });
    }
  },

  setWallet(inventoryState: IInventoryState, payload: IWallet) {
    if (!inventoryState.isFetchingWallets) {
      const indexToUpdate = inventoryState.wallets.findIndex(
        (wallet) => wallet.symbol === payload.symbol,
      );
      if (indexToUpdate >= 0) {
        const walletsArrCopy = [...inventoryState.wallets];
        walletsArrCopy.splice(indexToUpdate, 1, payload);
        inventoryState.wallets = walletsArrCopy;
      }
    }
  },

  updateIsFetchingWallets(inventoryState: IInventoryState, payload: boolean) {
    inventoryState.isFetchingWallets = payload;
  },

  updateWalletBalances(inventoryState: IInventoryState, payload: IWallet[]) {
    const updatedWallets = inventoryState.wallets.map((wallet) => {
      const updatedBalances = payload.find(
        (update) => update.symbol === wallet.symbol,
      );
      if (updatedBalances) {
        return { ...wallet, ...updatedBalances };
      }
      return wallet;
    });
    inventoryState.wallets = updatedWallets;
  },

  updateWalletCoinPrices(
    inventoryState: IInventoryState,
    payload: { [key: string]: { USD: number } },
  ) {
    const { wallets } = inventoryState;

    const updatedWallets = wallets.map((wallet: IWallet) => {
      if (payload[wallet.symbol]) {
        return { ...wallet, coinPrice: payload[wallet.symbol]['USD'] };
      }
      return wallet;
    });
    inventoryState.wallets = updatedWallets;
  },

  updateUserItem(
    inventoryState: IInventoryState,
    payload: IUpdateUserItemData,
  ) {
    const { userItems } = inventoryState;
    const itemIndex = userItems.findIndex(
      (i) => i.uniqueInventoryPath === payload.itemUniqueInventoryPath,
    );
    const item = userItems[itemIndex];

    if (!item) {
      return;
    }

    if (payload.action === 'send' || payload.action === 'exchange') {
      const newQuantity = item.quantity - (payload.quantity || 0);

      if (newQuantity <= 0) {
        // Remove item from state
        inventoryState.userItems = userItems.filter((i) => i !== item);
      } else {
        // Create a new item object with the updated quantity
        const newItem = { ...item };
        if (newItem.fungible) {
          newItem.quantity = newQuantity;
        } else {
          if (newItem.quantity !== 1) {
            throw new Error(
              `Expected new quantity for NFT ${newItem.uniqueInventoryPath} to be either 0 or 1. Got ${newItem.quantity}`,
            );
          }
          newItem.quantity = newItem.quantity;
        }

        // Insert new item object into the right place in new array
        inventoryState.userItems = [
          ...inventoryState.userItems.slice(0, itemIndex),
          newItem,
          ...inventoryState.userItems.slice(itemIndex + 1),
        ];
      }
    }
  },

  updateUserItems(inventoryState: IInventoryState, userItems: UserItem[]) {
    function getCanBridgeTo(item: UserItem) {
      if (!item.canBridgeTo || item.canBridgeTo.length === 0) {
        return item.canBridgeTo;
      }

      for (const bannedName of bridgingBannedTokenNames) {
        if (item.name.includes(bannedName)) {
          return [];
        }
      }

      return item.canBridgeTo;
    }

    function getFullName(item: UserItem) {
      if (item.fungible) {
        return item.name;
      }

      // Vox already have the instance ID in their
      if (/#[0-9]+$/.test(item.name)) {
        return item.name;
      }

      return `${item.name} #${item.nonFungibleInstanceId}`;
    }

    function getSendId(item: UserItem) {
      if (item.network === ChainNetwork.GYRI) {
        const classKey = item.gyriTokenClassKey;
        return `${classKey.collection}|${classKey.category}|${classKey.type}|${classKey.additionalKey}`;
      }

      if (item.network === ChainNetwork.OFF_CHAIN_TOKEN) {
        const classKey = item.gyriTokenClassKey;
        return `${classKey.collection}|${classKey.category}|${classKey.type}|${classKey.additionalKey}`;
      }

      if (item.network === ChainNetwork.ETH_TREASURE_CHEST) {
        return '';
      }

      if (item.network === ChainNetwork.ETHEREUM) {
        if (item.ethereumTokenStandard === EthereumTokenStandard.ERC1155) {
          return item.ethereumFullId;
        }

        if (item.ethereumTokenStandard === EthereumTokenStandard.ERC721) {
          return item.nonFungibleInstanceId;
        }
      }

      return '';
    }

    function getIsExchangeable(item: UserItem) {
      if (item.network === ChainNetwork.GYRI) {
        return item.gyriExchanges?.length > 0;
      }

      return item.isExchangeable;
    }

    // Augment the items from the API with some additional computed information
    inventoryState.userItems = userItems.map((item: UserItem) => {
      const newItem = {
        ...item,

        uniqueInventoryPath: getUniqueInventoryPath(item),
        fullName: getFullName(item),
        sendId: getSendId(item),
        canBridgeTo: getCanBridgeTo(item),
        isExchangeable: getIsExchangeable(item),
      };
      return newItem.network === ChainNetwork.ETHEREUM
        ? (newItem as EthUserItem)
        : newItem.network === ChainNetwork.GYRI
        ? (newItem as GyriUserItem)
        : (newItem as UserItem);
    });
  },

  updateClaimFees(inventoryState: IInventoryState, payload: IClaimFee[]) {
    if (payload) {
      inventoryState.claimFees = payload;
    }
  },

  setGyriExchangeRewards(
    inventoryState: IInventoryState,
    rewards: IExchangeReward[],
  ) {
    inventoryState.mostRecentExchangeRewards = rewards;
  },

  incrementWalletBalance(
    inventoryState: IInventoryState,
    { symbol, amount }: { symbol: string; amount: string },
  ) {
    try {
      const coercedSymbol =
        /\[(?:ETH|GYRI)\]$/.test(symbol) || symbol === 'ETH'
          ? symbol
          : `${symbol}[ETH]`;

      const walletIndex = inventoryState.wallets.findIndex(
        (w) => w.symbol === coercedSymbol,
      );

      if (walletIndex === -1) {
        console.warn(`Could not find wallet with symbol ${symbol}`);
        return;
      }

      const wallet = inventoryState.wallets[walletIndex];

      // Stringified with no more than 8 decimal places
      const newConfirmed = Number.parseFloat(
        Math.max(Number(wallet.balance.confirmed) + Number(amount), 0).toFixed(
          8,
        ),
      ).toString();

      const newUnconfirmed = Number.parseFloat(
        Math.max(
          Number(wallet.balance.unconfirmed) + Number(amount),
          0,
        ).toFixed(8),
      ).toString();

      inventoryState.wallets = [
        ...inventoryState.wallets.slice(0, walletIndex),
        {
          ...wallet,
          balance: {
            ...wallet.balance,
            confirmed: newConfirmed,
            unconfirmed: newUnconfirmed,
          },
        },
        ...inventoryState.wallets.slice(walletIndex + 1),
      ];
    } catch (err) {
      console.warn(err);
    }
  },

  updateVestingSchedule(
    inventoryState: IInventoryState,
    { gyriId, schedule }: { gyriId: string; schedule: IFilmTokenVestingItem[] },
  ) {
    if (gyriId && schedule?.length > 0) {
      deepSet(inventoryState, `vestingScheduleByGyriId.${gyriId}`, schedule);
    }
  },

  updateUsersMintTokenDetails(
    inventoryState: IInventoryState,
    { gyriId, data }: { gyriId: string; data: IUsersMintTokenDetails },
  ) {
    if (gyriId && data) {
      deepSet(inventoryState, `userMintTokenDetails.${gyriId}`, data);
    }
  },

  updateTokenRedistributionPoolAmount(
    inventoryState: IInventoryState,
    { gyriId, amount }: { gyriId: string; amount: number },
  ) {
    if (gyriId && amount) {
      deepSet(
        inventoryState,
        `tokenRedistributionPoolAmount.${gyriId}`,
        amount,
      );
    }
  },
};
